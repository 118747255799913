/* eslint-disable no-unused-vars */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import Menu from '../../../components/Menu/Navbar';
import Header from '../../../components/Header/header';
import Logo from '../../../components/Logo/logo';
import Footer from '../../../components/Footer/footer';
import { ContainerGeneral, TituloGrande, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview';
import PDFEscuelaDePadel from '../../../components/Airbus/PDFEscritoConsentimiento/escritoconsentimiento';

const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`;

const Boton = styled.a`
  font-size: 15px;
  width: 90%;
  font-family: 'Kobern';
  display: inline-block;
  margin-bottom: 16px;
  padding: 15px 20px;
  background-color: #a0f000;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TextSection = styled.div`
  margin: 30px 0px;
  padding: 10px;
  line-height: 1.6;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const EscuelaDePadel = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <ContainerGeneral>
        <ContainerTexto>
          <TituloGrande>
            Comunicado Oficial de G.E Airbus Pádel Club
          </TituloGrande>

          <TextSection>
            <h3>Estimados socios y socias del Grupo Empresa Airbus Sevilla:</h3>
            <p>Se informa sobre asuntos relevantes en relación con los beneficios y servicios para los socios/as de G.E Airbus Pádel Club. A continuación, se detallan los procedimientos y condiciones:</p>

            <h4>1. Precio de las pistas para socios/as:</h4>
            <ul>
              <li><strong>Tarifa especial:</strong> 2,50 € por jugador/a por 1 hora y media (válida exclusivamente para socios del Grupo Empresa Airbus Sevilla).</li>
              <li><strong>No socios/as:</strong> Deben abonar la diferencia hasta el precio estipulado por la pista.</li>
              <li><strong>Reservas:</strong> Si más de un socio/a participa en un mismo partido, la reserva debe realizarse llamando al número <strong>625 305 902</strong> (lunes a viernes, 9:30-14:00) para aplicar el descuento.</li>
            </ul>

            <h4>2. Reservas de pistas los fines de semana (in situ):</h4>
            <p>Válida exclusivamente para socios del Grupo Empresa Airbus Sevilla, Las reservas podrán realizarse a través del teléfono de la zona Padel del Club <strong>625 305 902</strong> siguiendo este procedimiento:</p>

            <ol>
              <li>Realizar un Bizum de 10,00€ al número <strong>625 305 902</strong>.</li>
              <li>Enviar el justificante de pago al mismo número.</li>
              <li>Especificar: Nombre y apellido del socio/a responsable + Nº de pista + Horario de reserva.</li>
              <li>Una vez confirmado, se podrá acceder a la pista reservada con el soporte del personal de mantenimiento del Club.</li>
            </ol>

            <h4>3. Alquiler de palas de pádel:</h4>
            <ul>
              <li><strong>Socios/as:</strong> Alquiler gratuito.</li>
              <li><strong>No socios/as:</strong> Tarifa de 2€ por pala. Pago en la Oficina de Pádel (junto a las pistas) de lunes a viernes o mediante Bizum al <strong>625 305 902</strong>, indicando el concepto "PALA + 1". Mostrar el justificante de pago al personal de mantenimiento, quien entregará la pala.</li>
              <li>Es necesario entregar el DNI como garantía y firmar un documento de responsabilidad por mal uso, rotura o pérdida.</li>
            </ul>

            <h4>4. Reserva de pistas en Playtomic:</h4>
            <p>El servicio de alquiler de pistas a través de la plataforma Playtomic sigue activo y operativo.</p>
            <p><strong>Nota importante:</strong> Las reservas por vía telefónica solo están disponibles cuando juegan varios socios/as en un mismo partido, con el fin de aplicar la tarifa especial socio.</p>




            <ButtonContainer>
              <Boton
                href="https://playtomic.io/tenant/701453e5-a68b-4e5f-9038-a4137c80e868?utm_source=app_ios&utm_medium=share"
                target="_blank"
                rel="noopener noreferrer"
              >
                Reserva Playtomic
              </Boton>
            </ButtonContainer>

            <p><strong>Sin más, agradecemos su atención y compromiso con el Club.</strong> </p>
            <p><strong>          Atentamente, <br></br>
              La Dirección<br></br>
              Club G.E Airbus Pádel</strong> </p>



          </TextSection>



          <PDFEscuelaDePadel />
        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
    </div>
  );
};

export default EscuelaDePadel;
