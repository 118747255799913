const generarRutaDesdeNombre = (evento) => {
  return {
    ...evento,
    ruta: '/eventos/' + evento.nombre.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''),
  };
};


export const eventos = [
  
  //   mostrarBackend1: true,   
  //   mostrarBackend2: false,  








  {
    id: 26,
    nombre: 'Torneo NSTA Kids Rio Grande',
    fecha: '15/12/2024',
    descripcion: 'Torneo Beneféfico de Navidad en Rio Grande, dona un regalo a los niñ@s mas necesitados. Domingo 15 de Diciembre.',
    imagenUrl: '/Events/Torneo Beneféfico de Navidad en Rio Grande.jpg',
    club: 'Rio Grande',
    urlInscripcion: '', // URL FORMULARIO INSCRIPCIÓN
    mostrarBackend1: true,
  },

  {
    id: 25,
    nombre: 'Torneo Navidad Palomares 2024',
    fecha: '30/11/2024',
    descripcion: 'Torneo Navidad en Palomares, Sabado 30 de Noviembre.',
    imagenUrl: '/Events/Torneo Navidad Palomares 2024.jpg',
    club: 'Palomares',
    urlInscripcion: '', // URL FORMULARIO INSCRIPCIÓN
  },

  {
    id: 24,
    nombre: 'Torneo Otoño Rio Grande',
    fecha: '30/11/2024 - 01/12/2024',
    descripcion: 'Torneo Otoño Rio Grande Sabado 30 de Noviembre y Domingo 1 de Diciembre.',
    imagenUrl: '/Events/Torneo Otoño Rio Grande.jpg',
    club: 'Rio Grande',
    urlInscripcion: '',      //INSCRIPCIÓN
  },

  {
    id: 23,
    nombre: 'Torneo NSTA Kids Bellavista Huelva Club',
    fecha: '10/11/2024',
    descripcion: 'Torneo NSTA Kids Bellavista Huelva Club, día Domingo 10 de Noviembre del 2024.',
    imagenUrl: '/Events/Torneo NSTA Kids Bellavista Huelva Club.jpeg',
    club: 'Bellavista',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 22,
    nombre: 'Torneo NSTA Kids Rio Grande',
    fecha: '01/11/2024',
    descripcion: 'Torneo NSTA Kids en Rio Grande el 1 de Noviembre del 2024.',
    imagenUrl: '/Events/Torneo NSTA Kids Rio Grande.jpeg',
    club: 'Rio Grande',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 21,
    nombre: 'Quedada Halloween',
    fecha: '01/11/2024',
    descripcion: 'Quedada Halloween en G.E Airbus.',
    imagenUrl: '/Events/HalloweenAirbus.jpeg',
    club: 'Airbus',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 20,
    nombre: 'Super quedada Halloween',
    fecha: '01/11/2024',
    descripcion: 'Super quedada Halloween en Palomares.',
    club: 'Palomares',
    imagenUrl: '/Events/HallowenPalomares.jpeg',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 19,
    nombre: 'Torneo NSTA Series 1.0',
    fecha: '11/10/2024 - 13/10/2024',
    descripcion: 'Torneo NSTA Series 1.0 Bellavista Huelva Club!',
    imagenUrl: '/Events/TORNEO NSTA SERIES 1.0.jpg',
    club: 'Bellavista',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 18,
    nombre: 'Torneo Social Club Rio Grande 2024',
    fecha: '30/09/2024 - 05/10/2024',
    descripcion: 'Torneo Social Club Rio Grande, Categorías: Absoluto Masculino y Femenino Veterano + 45 Masculino',
    imagenUrl: '/Events/Torneo Social Club Rio Grande.jpg',
    club: 'Rio Grande',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 17,
    nombre: 'Spartan Adultos Tenis Tour 2024',
    fecha: '19/08/2024 - 25/08/2024',
    descripcion: 'Spartan Adultos Tenis Tour 2024 del 19 de Agosto al 25 de Agosto. Categorías Absoluto y +35.',
    imagenUrl: '/Events/spartanAbsoluto2024.jpg',
    club: 'Bellavista',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 16,
    nombre: 'Spartan Tenis Tour 2024',
    fecha: '19/08/2024 - 25/08/2024',
    descripcion: 'Spartan Tenis Tour 2024 del 19 de Agosto al 25 de Agosto.',
    imagenUrl: '/Events/spartan2024.jpg',
    club: 'Bellavista',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 15,
    nombre: 'Torneo de Verano Bellavista Huelva Club',
    fecha: '12/07/2024 - 14/07/2024',
    descripcion: 'Torneo de verano los días 12, 13 y 14 de Julio en Bellavista Huelva Club.',
    imagenUrl: '/Events/Torneo de verano en Bellavista Huelva Club.jpg',
    club: 'Bellavista',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 14,
    nombre: 'Torneo Federado Bellavista Huelva Club',
    fecha: '05/07/2024 - 07/07/2024',
    descripcion: 'Torneo Federado en Bellavista Huelva Club del 5 de Julio al 7 de Julio 2024.',
    imagenUrl: '/Events/Torneo Federado Bellavista Huelva Club.jpg',
    club: 'Bellavista',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 13,
    nombre: 'Torneo Benéfico para la investigación de la Ataxia Telangiectasia',
    fecha: '29/06/2024 - 30/06/2024',
    descripcion: 'Torneo Benéfico para la investigación de la Ataxia Telangiectasia',
    imagenUrl: '/Events/Torneo Benéfico para la investigación de la Ataxia Telangiectasia.jpeg',
    club: 'Airbus',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 12,
    nombre: 'Torneo Nocturno',
    fecha: '28/06/2024',
    descripcion: 'Torneo Nocturno Viernes 28 de Junio.',
    imagenUrl: '/Events/Torneo Nocturno.jpeg',
    club: 'Palomares',

    urlInscripcion: '', // FORMULARIO
  },




  {
    id: 11,
    nombre: 'Torneo Dobles NSTA',
    fecha: '1/05/2024',
    descripcion: 'Torneo Dobles NSTA en Club Rio Grande.',
    imagenUrl: '/Events/TORNEO-DOBLES-NSTA.jpg',
    club: 'Rio Grande',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 10,
    nombre: 'Circuito TWE en Río Grande',
    fecha: '26/04/2024 - 28/04/2024',
    descripcion: 'Continuamos con el 2º Circuito Tennis Warehouse Europe con la celebración del 3er torneo en las magníficas instalaciones de la Asociación Recreativa Río Grande.',
    imagenUrl: '/Events/CircuitoTW.jpg',
    club: 'Rio Grande',
    urlInscripcion: 'https://docs.google.com/forms/d/e/1FAIpQLSd1YV3W3lkakI1Wnmf7swVSEF5kThUity6q-FpxtjHXVP9mAg/viewform?vc=0&c=0&w=1&flr=0', // FORMULARIO
  },

  {
    id: 9,
    nombre: 'III Torneo NSTA Kids',
    fecha: '28/04/2024',
    descripcion: 'Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y fundamentalmente que se enamoren del tenis.',
    imagenUrl: '/Events/3torneonstakids.jpg',
    club: 'Bellavista',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 8,
    nombre: 'Torneo de padel Mucopolisacaridosis',
    fecha: '04/05/2024',
    descripcion: 'Torneo de padel solidario por la Mucopolisacaridosis.',
    imagenUrl: '/Events/mucopolisacaridosis.jpg',
    club: 'Palomares',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 7,
    nombre: 'Campus Semana Santa en G.E Airbus',
    fecha: '25/03/2024 - 27/03/2024',
    descripcion: 'Campus Semana Santa los dias 25, 26, y 27 de Marzo en G.E Airbus.',
    imagenUrl: '/Events/campus semana santa AIRBUS.jpg',
    club: 'Airbus',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 6,
    nombre: 'Torneo de Pádel en Palomares Pádel Club y en Bellavista Huelva Club',
    fecha: '08/06/2024',
    descripcion: 'Torneo de Pádel en Palomares Pádel Club y en Bellavista Huelva Club, ida 27/04/2024 vuelta 08/06/2024.',
    imagenUrl: '/Events/Torneo de Pádel en Palomares Pádel Club y en Bellavista Huelva Club.jpg',
    club: 'Palomares',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 5,
    nombre: 'Intensivo Competición Semana Santa',
    fecha: '25/03/2024 - 27/03/2024',
    descripcion: 'Intensivo Competición Semana Santa los dias 25, 26 y 27 de Marzo en Club Rio Grande.',
    imagenUrl: '/Events/Intensivo Competición Semana Santa.jpg',
    club: 'Rio Grande',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 4,
    nombre: 'Campeonato de Andalucía Alevín y Cadete Fase Provincial Huelva',
    fecha: '07/03/2024 - 10/03/2024',
    descripcion: 'La fase provincial del Campeonato de Andalucía, en las categorías Alevín y Cadete, se celebrará en el Bellavista Huelva Club. Este evento deportivo reunirá a los jóvenes talentos del tenis, quienes competirán por avanzar a la siguiente etapa del campeonato regional.',
    imagenUrl: '/Events/CAMPEONATO DE ANDALUCIA ALEVIN Y CADETE.jpg',
    club: 'Bellavista',
    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 3,
    nombre: '7º circuito tenis de Huelva',
    fecha: '01/03/2024 - 03/03/2024',
    descripcion: 'El torneo federado se celebrará del viernes 1 al domingo 3 de marzo, como parte del 7º circuito de tenis de Huelva.',
    imagenUrl: '/Events/7º circuito tenis de Huelva.jpg',
    club: 'Bellavista',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 2,
    nombre: 'NSTA Family',
    fecha: '28/02/2024',
    descripcion: 'Es uno de los últimos eventos diseñados por NSTA con el objetivo de disfrutar del tenis en familia. Consiste en una jornada de clases de tenis dirigidas a todo el núcleo familiar, donde comparten la misma clase mama, papa, hij@s, abuelos, tíos, primos, etc.',
    imagenUrl: '/Events/NSTAFAMILY.jpg',
    club: 'Rio Grande',

    urlInscripcion: '', // FORMULARIO
  },

  {
    id: 1,
    nombre: 'NSTA Kids',
    fecha: '03/03/2024',
    descripcion: 'Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma.',
    imagenUrl: '/Events/TorneoNSTAKidsRosa.jpg',
    club: 'Rio Grande',

    urlInscripcion: '', // FORMULARIO
  },

].map(generarRutaDesdeNombre); // Aquí agregamos la ruta automáticamente
