import React from 'react';
import { useParams } from 'react-router-dom'; // Para obtener el parámetro `nombre` desde la URL
import styled, { keyframes } from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import Footer from '../../components/Footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { eventos } from '../../components/-EVENTOS/-Eventos'; // Importar el archivo con los eventos
import FireStoreBackend1 from '../../components/-FireStoreBackend/firestoredatos1'; // Importar FireStoreBackend
import FireStoreBackend2 from '../../components/-FireStoreBackend/firestoredatos2'; // Importar FireStoreBackend

const EventoDetalle = () => {
  const { nombre } = useParams(); // Obtener el `nombre` desde la URL
  const evento = eventos.find((evento) => evento.ruta === `/eventos/${nombre}`); // Buscar el evento por su ruta

  if (!evento) {
    return <h2>Evento no encontrado</h2>;
  }

  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerGeneral>
        <TituloEvento>{evento.nombre}</TituloEvento>
        <FechaEvento>
          <IconoFecha icon={faCalendarAlt} /> {evento.fecha}
        </FechaEvento>
        <ImagenEvento src={evento.imagenUrl} alt={evento.nombre} />
        <ContainerTexto>
          <Descripcion>{evento.descripcion}</Descripcion>
        </ContainerTexto>
        {evento.mostrarBackend1 && <FireStoreBackend1 />}
        {evento.mostrarBackend2 && <FireStoreBackend2 />}

      </ContainerGeneral>
      <Footer />
    </div>
  );
};


export default EventoDetalle;

// Animación
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Estilos
const ContainerGeneral = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lumier', sans-serif;
  min-height: 100vh;
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
`;

const TituloEvento = styled.h2`
  font-weight: bold;
  color: #4b4b4b;
  margin-bottom: 10px;
  margin-top: 50px;
  text-align: center;
  font-size: 29px;
`;

const FechaEvento = styled.div`
  color: #4b4b4b;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
`;

const IconoFecha = styled(FontAwesomeIcon)`
  margin-right: 8px; 
`;

const ImagenEvento = styled.img`
  width: 80%;
  max-width: 700px;
  border-radius: 10px;
  margin-top: 30px;
  align-self: center;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const ContainerTexto = styled.div`
  max-width: 60%;
  text-align: left;
  margin: 20px auto;
  margin-bottom: 100px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;

  @media (max-width: 768px) {
    max-width: 94%;
  }
`;

const Descripcion = styled.div`
  text-align: left;
  font-family: 'RNSMiles';
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  margin-top: 20px;
`;
