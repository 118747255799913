import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar, 
  faGraduationCap,
  faCalendarAlt, // para alquiler de pistas
  faCoffee, // para kiosko bar
  faSun,
  faBuilding,
  faClipboardList

} from '@fortawesome/free-solid-svg-icons'

const ContenedorPrincipal = styled.div`
  margin-bottom: 50px;
`



const TituloCabeza = styled.h3`
  font-family: 'GarnetLight';
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 26px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 10%,
    rgb(242, 242, 242) 45%,
    transparent 95%,
    transparent 100%
  );
`



const ContenedorApartado = styled.div`
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px;
`
const Cards = styled.figure`
  background-color: ${(props) => props.color};
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 325px;
  box-sizing: border-box;
  margin: 5px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 10px 10px rgb(173, 173, 173);
  background-color: #a0f000cf;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 45%;
    max-height: 200px;
  }
`
const Imagen = styled.img`
  margin: 0;
  width: 100%;
  height: 200px;
  will-change: transform;

  @media (max-width: 768px) {
    height: 110px;
  }
`
const Capa = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > svg {
    margin-bottom: 10px;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`
const Titulo = styled.h3`
  color: #000000;
  font-weight: 400;
  font-family: 'GarnetLight';
  margin-left: 4px;
  margin-right: 4px;

  font-size: 20px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`



const Card = () => {
  return (
    <>
      <ContenedorPrincipal>

        <ContenedorApartado>
   <Cards >
            <Link to="/clubs/airbus/escueladepadel">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faGraduationCap  }
                  size="2x"
                  color="white"
                />
                <Titulo>ESCUELA DE PADEL</Titulo>
              </Capa>
            </Link>
          </Cards>


        <Cards >
            <Link to="/clubs/airbus/informaciondeservicios">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faClipboardList}
                  size="2x"
                  color="white"
                />
                <Titulo> Información de Servicios para Socios del G.E Airbus</Titulo>
              </Capa>
            </Link>
          </Cards>


          <Cards >
            <Link to="/clubs/airbus/eventos">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faCalendar}
                  size="2x"
                  color="white"
                />
                <Titulo>EVENTOS</Titulo>
              </Capa>
            </Link>
          </Cards>


         
       
          <Cards >
            <Link to="/clubs/airbus/campusdeverano">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faSun}
                  size="2x"
                  color="white"
                />
                <Titulo>CAMPUS DE VERANO</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards >
            <Link to="/clubs/airbus/alquilerdepistas">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size="2x"
                  color="white"
                />
                <Titulo>ALQUILER DE PISTAS</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards >
            <Link to="/clubs/airbus/kioskobar">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faCoffee}
                  size="2x"
                  color="white"
                />
                <Titulo>KIOSKO BAR</Titulo>
              </Capa>
            </Link>
          </Cards>


    

        </ContenedorApartado>



        
        <TituloCabeza>CONTACTO E INSTALACIONES CLUB </TituloCabeza>
        <ContenedorApartado>

          <Cards >
          <Link to="/clubs/airbus/instalaciones">
            <Imagen />
            <Capa>
              <FontAwesomeIcon icon={faBuilding} size="2x" color="white" />
              <Titulo>
              INSTALACIONES CLUB
              </Titulo>
            </Capa>
            </Link>
          </Cards>

         

        </ContenedorApartado>

      </ContenedorPrincipal>
    </>
  )
}

export default Card
