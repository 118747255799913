import { eventos } from '../../components/-EVENTOS/-Eventos.js';  // Importa los datos desde el archivo Eventos.js
import $ from 'jquery';
import './evo-calendar.css';
import './evo-calendar.js';
import { useEffect, useCallback, useRef } from 'react';
import Menu from '../../components/Menu/Navbar.jsx';
import Header from '../../components/Header/header.jsx';
import Logo from '../../components/Logo/logo.jsx';
import Footer from '../../components/Footer/footer.jsx';
import { ContainerCalendario } from './CalendarioStyle';

// Array de colores predefinidos fuera del componente
const colores = [
  "#ff5733", // Rojo anaranjado
  "#33ff57", // Verde
  "#3357ff", // Azul
  "#f3ff33", // Amarillo
  "#ff33a8", // Rosa
  "#33fff5", // Turquesa
  "#8e44ad", // Morado
  "#e74c3c", // Rojo fuerte
];

const Calendario = () => {
  // Variable para mantener el índice actual con useRef
  const indiceColorRef = useRef(0);

  // Función para asignar colores secuenciales
  const asignarColorSecuencial = useCallback(() => {
    const color = colores[indiceColorRef.current];
    indiceColorRef.current = (indiceColorRef.current + 1) % colores.length; // Avanza al siguiente color
    return color;
  }, []); // No incluye `colores` como dependencia porque está fuera

  useEffect(() => {
    if (typeof $ !== 'undefined') {
      const eventosProcesados = eventos.map(evento => ({
        id: evento.id,
        name: evento.nombre,
        date: convertirFecha(evento.fecha),
        type: 'Evento importante',
        description: evento.descripcion,
        color: asignarColorSecuencial(), // Asigna un color secuencialmente
        url: evento.ruta,
      }));

      // Inicializar Evo Calendar con los eventos procesados
      $("#calendar").evoCalendar({
        language: 'es',
        calendarEvents: eventosProcesados,
      });
    } else {
      console.error("jQuery no está cargado. Asegúrate de que jQuery se cargue antes de Evo Calendar.");
    }
  }, [asignarColorSecuencial]);

  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerCalendario>
        <div id="calendar"></div>
      </ContainerCalendario>
      <Footer />
    </div>
  );
};


// Función para convertir las fechas de 'dd/mm/yyyy' a 'mm/dd/yyyy'
function convertirFecha(fecha) {
  if (fecha.includes(' - ')) {
    // Si la fecha contiene un rango
    const [inicio, fin] = fecha.split(' - ');
    return [formatearFecha(inicio), formatearFecha(fin)];
  } else {
    // Si es una fecha única
    return formatearFecha(fecha);
  }
}

// Función auxiliar para formatear una sola fecha
function formatearFecha(fecha) {
  const partes = fecha.split('/');
  return `${partes[1]}/${partes[0]}/${partes[2]}`;
}


export default Calendario;