import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0px auto;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
  font-family: 'GarnetLight';
  font-size: 25px;
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfLink = styled.a`
  color: #a0f000;
  font-size: 20px;
  text-decoration: underline;
`;

// Función para detectar dispositivos iOS
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// Función para detectar dispositivos Android
const isAndroid = () => /Android/i.test(navigator.userAgent);

const Documentacion = () => {
  const [iframeHeight, setIframeHeight] = useState('800px');

  useEffect(() => {
    if (isAndroid()) {
      setIframeHeight('200px');
    }
  }, []);

  return (
    <div>
      <Container>
        <ContainerTexto>
          <NSAPlusTitulo>ESCRITO CONSETIMIENTO MATERIAL DEPORTIVO G.E AIRBUS PADEL CLUB</NSAPlusTitulo>
        </ContainerTexto>
        {isIOS() ? (
          // Enlace de descarga para dispositivos iOS
          <PdfLink href="/PDF/CONSENTIMIENTO_MATERIAL_DEPORTIVO.pdf" target="_blank" rel="noopener noreferrer">
            Descargar PDF
          </PdfLink>
        ) : (
          // Mostrar el PDF en un iframe para otros dispositivos
          <iframe
            style={{ width: '100%', height: iframeHeight, border: 'none' }}
            src="/PDF/CONSENTIMIENTO_MATERIAL_DEPORTIVO.pdf"
            title="Escuela de Padel"
          ></iframe>
        )}
      </Container>
    </div>
  );
};

export default Documentacion;
